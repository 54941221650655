/* You can add global styles to this file, and also import other style files */
/* All sortable column of table are blue */


*{
    box-sizing: border-box;
    font-style: normal;
   
  }
  
  
  html,body{
    background: #F5F6F8;
    color: black;
  }
  
  
  th[sortable]{
      color: #192EB0;
  }
  
  tr{
    font-size: 11px;
  }
  tr th{
    font-size: 13px;
  }
  table th{
    font-size: 13px !important;
  }
  table td{
    font-size: 11px !important;
  }
  /* table tr th:first-child{
    width: 60px !important;
  } */
  
  /* table tr td:first-child{
    width: 30px !important;
  } */
  input:disabled{
    cursor: not-allowed;
  }
  
  select:disabled{
    cursor: not-allowed;
  }
  
  /* css issues */
  .flex_row{
    display: flex  !important;
    flex-direction: row !important;
  }
  .flex_column{
    display: flex !important;
    flex-direction: column !important;
    margin-top: 2px;
  }
  .flex_grow_2{
    flex-grow: 2;
  }
  .flex_wrap{
    flex-wrap: wrap !important;
  }
  .justify_content_space_evenly{
    justify-content: space-evenly !important;
  }
  .justify_content_space_around{
    justify-content: space-around !important;
  }
  .justify_content_center{
    justify-content:center !important;
  }
  /* .container{
    width: calc(80vw) ;
    margin: 0 auto !important;
  } */
  .container_expand{
    width: 100% !important;
    /* height: 100% !important; */
  }
  
  
  
  .vertical span{
    /* display: flex; */
    /* flex-direction: column; */
    width: 80%;
  }
  /* .vertical span span{
    width: 100%;
    display: block;
  } */
  
  
  /* input,select,option{
    background-color: #585858 !important;
    color: #fff !important;
  } */
  
  input,select,option{
    background-color: white !important;
    color: black !important;
  }
  
  
  html *::-webkit-scrollbar {
    width: 12px !important;
    height:10px !important;
  }
  
  html *::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
  }
  
  html *::-webkit-scrollbar-thumb {
  background-color: #313131 !important;
  outline: 1px solid #191919 !important;
  }
  
  
  html *::-webkit-scrollbar
  {
      width: 12px;
      background-color: #F0F0F0 !important;
    width: 6px !important;
    /* padding-bottom: 5px; */
  }
  
  html *::-webkit-scrollbar-thumb
  {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px #F0F0F0 !important;
      background-color: #979797 !important;
    outline: none !important;
  }
  
  .page-link{
    background-color: #252525 !important;
    color: #fff !important;
  }
  
  .page-item.active .page-link{
    background-color: rgb(26, 26, 169) !important;
    color: #fff !important;
  }
  
  .modal-backdrop {
    z-index: 960 !important;
  }
  .modal{
    z-index: 961 !important;
  }
  
   /* to design tooltip of prime ng only work when we define css at root level*/
  .p-tooltip .p-tooltip-text {
    background-color: #F3F7FF !important;
    color: black !important;
    width: 10rem !important;
  }
  
  
   .p-multiselect-panel{
    background: #DDE8FF !important;
    border: 1px solid #BFD1F8 !important;
    box-sizing: border-box !important;
    box-shadow: 2px 2px 6px rgba(104, 124, 166, 0.2) !important;
    border-radius: 0px 10px 10px 10px !important;
   }
  
  .p-multiselect-header{
    background: #DDE8FF !important;
    box-sizing: border-box !important;
    border-radius: 0px 10px 10px 10px !important;
   }
  
   :host::ng-deep .p-table .p-sortable-column:not(.p-state-highlight):hover{
    background: transparent;
    color: #6d809A;
  }
  
   /* change css for sweet alert modal  */
  html * .swal2-container:not(.swal2-top):not(.swal2-top-start):not(.swal2-top-end):not(.swal2-top-left):not(.swal2-top-right):not(.swal2-center-start):not(.swal2-center-end):not(.swal2-center-left):not(.swal2-center-right):not(.swal2-bottom):not(.swal2-bottom-start):not(.swal2-bottom-end):not(.swal2-bottom-left):not(.swal2-bottom-right):not(.swal2-grow-fullscreen)>.swal2-modal{
     border-radius: 17px !important;
     box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06) !important;
   }
   
  html * .swal2-styled.swal2-confirm{
    box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
    border-radius: 4px;
  }
  
  html * .swal2-styled.swal2-cancel{
    border: 1px solid #C2CFE0;
    box-sizing: border-box;
    border-radius: 4px;
    color: #3E4958;
  }

  div:where(.swal2-container){
    z-index: 200000 !important;
  }

  div:where(.swal2-container) .swal2-select{
    margin: 1em auto !important;
  }

  img[src="../../../assets/logoBlue.png"]{
    width: 200px;
    height: auto;
}

img[src="../../../assets/blu_logo.svg"]{
  width: 200px !important;
  height: auto !important;
}

.ngx-otp-input{
  width: 3rem !important;
  height: 3.3rem !important;
  border-radius: 0.4rem !important;
  border: 1px solid #B4B4BB !important;  
  color: #2C66E3 !important;
}

.pac-container {
  background-color: #ffffff !important;
  border : 1px solid #E2E2E2 !important;
  font-size: 14px !important;
  color: #4F4F4F !important;
  font-weight: 400 !important;
  font-family: 'Poppins', sans-sarif !important;
  padding: 1rem !important;
}

.pac-item {
  color: #333 !important;
  padding: 0.2rem !important;
}

.pac-icon{
  display: none !important;
}

.pac-item-query{
  color: #4F4F4F !important;
  font-weight: 400 !important;
}

.pac-matched{
  color: #171717 !important;
  font-weight: 700 !important;
}

.pac-item, .pac-item-selected,.pac-item:hover {
  color: #979797 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}


